import { Helmet } from 'react-helmet';
import { Routes, Route, Link, Navlink } from "react-router-dom"
import Header from "components/common/Header";
import Home from "components/Home";
import { useState } from 'react';

function App() {



  return (
    <div className="App">
      <Helmet>
        <title>ath.cool | Token Increase Rate, Fair ATH Estimation, Potential Gains </title>
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-44CGM0HED9"></script>
        <script>
          {`
          function gtag(){(window.dataLayer || []).push(arguments)}
          gtag('js', new Date());

          gtag('config', 'G-44CGM0HED9');
          `}
        </script>
      </Helmet>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
      </Routes>
    </div>
  );
}

export default App;
