import React, { useEffect, useState } from "react"
import axios from "axios";
import { IoMdArrowDown } from "react-icons/io";
import { FaFaceSmile } from "react-icons/fa6";
import { FaFaceFrown } from "react-icons/fa6";
import { FaFaceMehBlank } from "react-icons/fa6";
import { FaSearch } from "react-icons/fa";
import e from "cors";

const importAll = (r) => {
    let images = {};
    r.keys().forEach((item, index) => { images[item.replace('./', '')] = r(item); });
    return images
};

const images = importAll(require.context('../../../public/assets/clogo', false, /\.(png|jpe?g|svg)$/));
const storageKeys = {
    apiRequestTime: "apiRequestTime",
    apiResponse: "apiResponse"
}

export default function MyTable() {

    const [loader, setLoader] = useState(true);
    const [search, setSearch] = useState('');
    const [apiData, setApiData] = useState([]);
    const [listData, setListData] = useState([]);
    const [averageStartRate, setAverageStartRate] = useState();
    const [averageEndRate, setAverageEndRate] = useState();

    const searchInList = (event) => {
        let value = event.target.value.trim().toLowerCase();
        if (value == '' || value.length < 2) {
            setListData(apiData);
            return;
        }

        if (apiData && apiData.filter) {
            let filteredResult = apiData.filter((elem) => elem.symbol.toLowerCase().includes(value) || elem.name.toLowerCase().includes(value));
            setListData(filteredResult);
        }
    }

    const fetchListData = () => {
        //First check localStorage to get data
        let apiRequestTimeString = localStorage.getItem(storageKeys.apiRequestTime);
        if (apiRequestTimeString && apiRequestTimeString.trim() != "") {
            let apiRequestTimeDate = new Date(apiRequestTimeString);
            let currentDate = new Date();
            if (Math.round((((currentDate - apiRequestTimeDate) % 86400000) % 3600000) / 60000) < 20) { //  < 20 Minutes
                let dataString = localStorage.getItem(storageKeys.apiResponse);
                if (dataString && dataString != "") {
                    let dataJson = JSON.parse(dataString);
                    if (dataJson && dataJson.length > 0) {
                        setApiData(dataJson);
                        setListData(dataJson);
                        setLoader(false);
                        return;
                    }
                }
            }
        }

        //If data not found in localStorage or expired then get it from API
        axios.get("https://965rwneom0.execute-api.eu-central-1.amazonaws.com/Prod/")
            .then(response => {
                return response;
            })
            .then(data => {
                let sortedData = data.data.sort((a, b) => (a.rank > b.rank) ? 1 : -1);
                setLoader(false);
                setApiData(sortedData);
                setListData(sortedData);
                localStorage.setItem(storageKeys.apiRequestTime, new Date());
                localStorage.setItem(storageKeys.apiResponse, JSON.stringify(sortedData));
                calculateAverageRate(data.data);
            })
    }

    const calculateAverageRate = (data) => {
        let total = 0;
        for (let i = 0; i < data.length; i++) {
            total = total + data[i].coinpub.tokenIncreasePercentage;
        }
        let exactAverage = total / data.length;
        setAverageStartRate(Math.round(exactAverage));
        setAverageEndRate(Math.round(exactAverage + (data.length / 2)));
    }

    useEffect(() => {
        fetchListData()
    }, [])


    return (
        <>
            <div class="px-3 sm:px-6 w-full mt-5">
                <div class="bg-white py-4 md:py-7 px-4 md:px-8 xl:px-10 rounded-lg">
                    <div class="sm:flex items-center justify-between text-sm font-bold">
                        <div>
                            <p tabindex="0" class="focus:outline-none text-base sm:text-lg md:text-xl lg:text-xl font-bold leading-normal text-blue-400">
                                Top 500 Coins
                            </p>
                        </div>

                        {/* <div>
                            <div class="py-3 px-4 flex items-center text-sm font-medium leading-none text-gray-600 bg-gray-200 hover:bg-gray-300 cursor-pointer rounded">
                                <p>Sort By:</p>
                                <select aria-label="select" class="focus:text-indigo-600 focus:outline-none bg-transparent ml-1">
                                    <option class="text-sm text-indigo-800">Latest</option>
                                    <option class="text-sm text-indigo-800">Oldest</option>
                                    <option class="text-sm text-indigo-800">Latest</option>
                                </select>
                            </div>
                        </div> */}
                        <div>
                            <div className="mr-auto relative border border-gray-200 rounded-lg mt-3">
                                <label htmlFor="search-input" className="text-black w-12 h-10 flex items-center justify-center absolute top-0 right-0">
                                    <FaSearch size={24} opacity={0.2} name="search" className="mt-2" />
                                </label>
                                <input type="text" onKeyUp={searchInList} id="search-input" className="h-12 pl-6 outline-none text-black bg-white rounded-xl text-sm sm:text-base font-medium placeholder-black/50 max-w-full w-[24.75rem]" placeholder="Search crypto currency to see detail..." />
                            </div>
                        </div>
                    </div>
                    <div className="clear-both"><br /></div>
                    <div class=" overflow-x-auto">
                        <table class="w-full whitespace-nowrap table-fixed">
                            <thead>
                                <tr tabindex="0" class="focus:outline-none h-16 font-bold border bg-gray-100 border-gray-100 rounded">
                                    <td >
                                        <div class="text-sm lg:text-base text-center">
                                            🪙 Coin
                                        </div>
                                    </td>
                                    <td class="hidden md:table-cell text-sm lg:text-base">
                                        <div class="text-center">
                                            🟢 Current Price
                                        </div>
                                    </td>
                                    <td >
                                        <div class="text-xs lg:text-base text-center">
                                            🚨 Circ.Token <br />Increase % <br />Since ATH
                                        </div>
                                    </td>
                                    <td>
                                        <div class="text-sm lg:text-base text-center">
                                            🔻 Adjusted<br /> ATH Value
                                        </div>
                                    </td>
                                    <td>
                                        <div class="text-sm lg:text-base text-wrap text-center">
                                            🌟 Potential Target
                                        </div>
                                    </td>
                                </tr>
                                <tr class="h-3"></tr>
                            </thead>
                            <tbody className={(!listData || listData.length <= 0) && !loader ? 'table-block' : 'hidden'}>
                                <tr>
                                    <td colSpan={3}>
                                        <div className="w-full flex pt-3 pb-6 opacity-40">
                                            <h2 className="font-bold pt-8 text-xl">Not found</h2>
                                        </div>
                                    </td>
                                    <td class="hidden md:table-cell"></td>

                                    <td class="hidden md:table-cell"></td>
                                </tr>
                            </tbody>
                            <tbody className={loader ? 'table-block' : 'hidden'}>
                                <tr>
                                    <td colSpan={3}>
                                        <div className="w-full pl-10 pt-3 pb-6 opacity-40">
                                            <img className="float-left mr-2 align-middle w-20" src="loader.gif" /> <h2 className="font-bold pt-8 text-xl">Loading...</h2>
                                        </div>
                                    </td>

                                    <td class="hidden md:table-cell"></td>

                                    <td class="hidden md:table-cell"></td>
                                </tr>
                            </tbody>
                            <tbody>
                                {listData.map((row, index) => {
                                    return (
                                        <>
                                            <tr tabindex="0" class="focus:outline-none h-20 border border-gray-100 rounded">
                                                <td class="pl-3 md:pl-5">
                                                    <div class="flex items-center">
                                                        <span className="text-sm pr-2 italic text-gray-400 hidden lg:block">#{row.rank}</span>
                                                        <img src={images[`${row.key}.png`]} className="w-8 mr-2 ml-1" />
                                                        <div className="-mt-5 lg:mt-0">
                                                            <span className="pr-4 text-wrap font text-sm md:text-base hidden lg:inline-block">{row.name}</span><br />
                                                            <span className="text-sm lg:text-xs font-bold pl-1">{row.symbol}</span>
                                                            <span className="text-sm pr-2 italic text-gray-400 block lg:hidden">#{row.rank}</span>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td class="hidden md:table-cell">
                                                    <div class="text-center">
                                                        ${Number(row.price.USD).toFixed(3)}
                                                    </div>
                                                </td>
                                                <td >
                                                    <div class="text-center font-bold">
                                                        {/* <FaFaceSmile className={row.coinpub.tokenIncreasePercentage <= averageStartRate ? 'inline-block text-green-600 size-8 pr-2' : 'hidden'} />
                                                        <FaFaceFrown className={row.coinpub.tokenIncreasePercentage >= averageEndRate ? 'inline-block text-red-600  size-8 pr-2' : 'hidden'} />
                                                        <FaFaceMehBlank className={row.coinpub.tokenIncreasePercentage > averageStartRate && row.coinpub.tokenIncreasePercentage < averageEndRate ? 'inline-block text-gray-500  size-8 pr-2' : 'hidden'} /> */}
                                                        %{row.coinpub.tokenIncreasePercentage < 0 ? 0 : row.coinpub.tokenIncreasePercentage}
                                                        {/* <span className="block">{(new Date()).getTime() - (new Date(row.athPrice.date).getTime())}</span> */}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div class="font-bold text-center">
                                                        <span className="block text-sm text-gray-400">${Number(row.athPrice.USD).toFixed(2)}</span>
                                                        <IoMdArrowDown className="block  mx-auto text-gray-600" />
                                                        <span className="text-md">${row.coinpub.adjustedAth.toFixed(2)}</span>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div class="font-bold text-center">
                                                        {row.coinpub.possibleRevenue}X
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr class="h-3"></tr>
                                        </>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div >
                </div >
            </div >
        </>
    )
}