import { NavLink } from "react-router-dom";
import { FaUserPlus } from "react-icons/fa";
import { GiWallet } from "react-icons/gi";
import { BiSolidDonateHeart } from "react-icons/bi";
import { TbCopy } from "react-icons/tb";
import { IoCloseCircle } from "react-icons/io5";
import { useState } from "react";

export default function Header() {

    const [supportTooltip, setSupportTooltip] = useState(false);
    const [coppiedTooltip, setcoppiedTooltip] = useState(false);


    const copyWalletAddress = () => {
        let walletAddress = "TUe2XdnJs5WPrYVKBfuQr23sJNX1CEjLXS";
        navigator.clipboard.writeText(walletAddress);
        setcoppiedTooltip(true);
        setTimeout(() => {
            setcoppiedTooltip(false);
        }, 3000)

    }

    return (
        <>
            <div className="bg-brand-color-1 border-white border-b-2 sticky top-0 z-50">
                <div className=" pl-4 sm:pl-10 pr-4 sm:pr-10 mx-auto h-20 flex items-center justify-between">
                    <NavLink className="font-extrabold text-4xl" to="/">
                        <img className="w-12 sm:w-14 float-left mr-2 align-middle" src="logo.png" /> <h1 className="float-left mt-3 text-2xl sm:text-4xl">ath<span className="text-brand-color">.cool</span></h1>
                    </NavLink>

                    <div className="">
                        {/* <p tabindex="0" class="focus:outline-none text-xl mt-5 font-bold opacity-70 leading-normal text-brand-color">
                        token increase rate, fair ath estimation, potential gains
                    </p> */}
                        <nav className="flex gap-x-2 text-m font-extrabold">
                            <a className="text-gray-700 transition duration-200 flex items-center gap-x-2 h-12 px-4 rounded-xl bg-brand-color-2 hover:bg-yellow-500" target="_blank" rel="noopener noreferrer" href="https://docs.google.com/forms/d/e/1FAIpQLSfcfkurm7tAj6Vo2dfJIUB6j10UienIP8ihEq1yYLTAgu5ytw/viewform?usp=sf_link">Contact</a>
                            <div className="text-white transition duration-200 flex items-center gap-x-2 h-12 px-4 rounded-xl bg-blue-400 hover:bg-blue-500 cursor-pointer" onClick={() => { setSupportTooltip(!supportTooltip) }}><BiSolidDonateHeart size={24} /></div>
                            {/* <a className="text-gray-700 transition duration-200 flex items-center gap-x-2 h-12 px-4 rounded-xl bg-brand-color-2 hover:bg-brand-color-2-hover" href="#">Front Runners</a> */}
                        </nav>
                    </div>
                </div>
            </div >
            <div className={supportTooltip ? 'block bg-white p-3 float-left w-[21rem] rounded-lg h-[21rem] fixed -mt-3 top-15 right-10 z-50 border-4 border-blue-400' : 'hidden'} >
                <div className="flex justify-center">
                    <IoCloseCircle className="absolute right-2 top-2 size-7 cursor-pointer" onClick={() => { setSupportTooltip(false) }} />
                    <h2 className="font-extrabold leading-10 float-left mr-1 text-lg text-blue-400">
                        <BiSolidDonateHeart className="float-left mt-2 mr-1 hidden sm:inline-block" /> Support <span className=" bg-brand-color-1 text-md pl-2 pr-2 pt-1 text-black pb-1 rounded-md">ath
                            <span className=" text-brand-color">.cool</span>
                        </span>
                    </h2>
                </div>
                <div className="clear-both"></div>
                <div className="flex justify-center items-center">
                    <img className="float-left size-40 mr-2 align-middle" src="qr.png" />
                </div>
                <div className="clear-both"></div>
                <div className="flex justify-between ">
                    <div className="text-left">Curreny <br /> Chain</div>
                    <div className="text-right"><b>USDT</b><br /><b>TRC20</b></div>
                </div>
                <div flex justify-between>
                    <TbCopy className="float-left size-8 mt-2 text-gray-600 hover:text-gray-800 cursor-pointer" onClick={copyWalletAddress} />
                    <input disabled className="border p-2 text-xs rounded-md mt-2 w-[85%] float-right" type="text" value="TUe2XdnJs5WPrYVKBfuQr23sJNX1CEjLXS" />
                    <span className={coppiedTooltip ? 'text-sm font-bold text-green-500' : 'hidden'}>Address copied.</span>
                </div>
            </div >
        </>
    )
}