import { BiSolidDonateHeart } from "react-icons/bi";
import { TbCopy } from "react-icons/tb";


export default function Spot() {

    const copyWalletAddress = () => {
    }

    return (
        <>
            {/* <div className="flex flex-wrap w-full px-3 sm:px-6 justify-between mt-4 sm:mt-6 mb-5 lg:mb-2">
                <div className="bg-white p-3 float-left w-[100%] lg:w-[75%] rounded-lg mb-4 sm:mb-3 h-80 hidden sm:block">
                    <a href=""><h1 className="font-extrabold text-base sm:text-3xl leading-10 float-left mr-0 mb-2 sm:mb-3 text-blue-400">Give <span className="text-black">me</span> hints? </h1></a>
                    <div className="clear-both"></div>
                    <div className="text-sm md:text-base">
                        <p className="p-3">
                            <span className="font-bold">🚨 Circ.Token Increase % Since ATH:</span> ath.cool calculates the circulating token amount for each coin at its ATH and compares it with the current circulating token amount. <span className=" hidden md:inline">This allows users to see the percentage change in token amount from ATH to the present.</span>
                        </p>
                        <p className="p-3">
                            <span className="font-bold">🔻 Adjusted ATH Value</span> This is the recalculated ATH value, taking into account the increase in circulating token amounts to reflect the most suitable ATH value under today's conditions.
                        </p>
                        <p className="p-3">
                            <span className="font-bold">🌟 Potential Target</span> Indicates the potential profit that would be achieved if the token's current price reaches the Adjusted ATH value.
                        </p>
                    </div>
                </div>
            </div > */}
        </>
    )
}