
import List from "./home/List"
import Spot from "./home/Spot"

export default function Home() {
  return (
    <>
      <div>
        <Spot />
        <List />
      </div>
    </>
  )
}